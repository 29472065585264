<!--
### Global mobile navigation
 -->
<script lang="ts">
  import { page } from "$app/stores";
  import Button from "@hyperfocal/design/components/Button/Button.svelte";
  import type { HTMLAttributes } from "svelte/elements";
  import { fade } from "svelte/transition";

  type MobileMenuProps = {
    /** Navigation for the mobile menu */
    nav: {
      label: string;
      href: string;
    }[];
    /** Whether the menu is open */
    open: boolean;
  } & HTMLAttributes<HTMLDivElement>;

  let { nav, open = $bindable() }: MobileMenuProps = $props();
  const fullPath = $derived(`${$page.url.pathname}${$page.url.hash}`);

  $effect(() => {
    if (fullPath) {
      open = false;
    }
  });
</script>

<style>
  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    grid-auto-rows: auto !important;
    z-index: var(--layer-1);
  }

  .inner,
  .nav,
  .actions {
    display: flex;
    flex-direction: column;
  }

  .inner {
    justify-content: space-between;
    height: 100%;
    flex: 1;
    padding: var(--size-28) 0 var(--size-16);
  }

  .nav {
    gap: var(--size-5);
  }

  .nav-item {
    font: var(--text-lg);
    font-weight: var(--weight-semibold);
    transition: color 250ms var(--ease-standard);
    &:hover,
    &.active {
      color: var(--color-accent);
    }
  }

  .actions {
    align-items: center;
    gap: var(--size-4);
    & :global(.primary-action) {
      font: var(--text-md) !important;
      font-weight: var(--weight-semibold) !important;
    }
  }
</style>

{#if open}
  <div class="mobile-menu --grid-page" transition:fade={{ duration: 250 }}>
    <div class="inner">
      <nav class="nav">
        {#each nav as { label, href }}
          <a
            class="nav-item"
            class:active={$page.url.pathname.startsWith(href)}
            {href}>{label}</a
          >
        {/each}
      </nav>

      <div class="actions">
        <a
          class="nav-item secondary-action"
          href="https://app.hyperfocal.photo/login">Login</a
        >
        <Button
          class="primary-action"
          fullwidth
          fat
          theme="primary"
          href="https://app.hyperfocal.photo/signup">Signup</Button
        >
      </div>
    </div>
  </div>
{/if}
