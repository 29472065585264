import { RudderAnalytics } from "@rudderstack/analytics-js";
import { untrack } from "svelte";

const { PUBLIC_RUDDER_KEY, PUBLIC_RUDDER_DATA_URL } = import.meta.env;

const analytics = new RudderAnalytics();

$effect.root(() => {
  analytics.load(PUBLIC_RUDDER_KEY, PUBLIC_RUDDER_DATA_URL, {});
  untrack(() => analytics);
});

export { analytics };
