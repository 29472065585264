<!--
### Animated hamburger icon
 -->
<script lang="ts">
  import type { HTMLAttributes } from "svelte/elements";

  type HamburgerProps = {
    /** Whether icon is in an active state */
    active: boolean;
  } & HTMLAttributes<HTMLDivElement>;

  const { active, class: className = "", ...props }: HamburgerProps = $props();
</script>

<style>
  .hamburger {
    position: relative;
    cursor: pointer;
    height: var(--hamburger-height, var(--size-4));
    width: var(--hamburger-width, var(--size-8));
    outline: none !important;
  }

  .line {
    position: absolute;
    background: currentColor;
    width: 100%;
    height: var(--hamburger-stroke, 2.25px);
    border-radius: var(--radius-full);
    transition:
      top 200ms 250ms var(--ease-standard),
      transform 200ms 50ms var(--ease-standard);
    .active & {
      transition:
        top 200ms 50ms var(--ease-standard),
        transform 200ms 250ms var(--ease-standard);
    }
    &.first {
      top: 0;
      .active & {
        top: 50%;
        transform: rotate(45deg);
      }
    }
    &.middle {
      top: 50%;
      .active & {
        transform: rotate(45deg);
      }
    }
    &.bottom {
      top: 100%;
      .active & {
        top: 50%;
        transform: rotate(-45deg);
      }
    }
  }
</style>

<div class="hamburger {className}" class:active {...props}>
  <span class="line first"></span>
  <span class="line middle"></span>
  <span class="line bottom"></span>
</div>
