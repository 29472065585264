<!--
### Global site footer
 -->
<script lang="ts">
  import type { InputValue } from "@portabletext/svelte";
  import type { HTMLAttributes } from "svelte/elements";
  import Richtext from "../Richtext/Richtext.svelte";
  import Button from "@hyperfocal/design/components/Button/Button.svelte";
  import logo from "@hyperfocal/design/assets/logo.svg?raw";

  type Link = {
    href: string;
    label: string;
  };

  type FooterProps = {
    /** Title of the content */
    title: string;
    /** Copy for the content */
    copy: InputValue;
    /** Primary call to action */
    primaryCta: Link;
    /** Secondary call to action */
    secondaryCta: Link;
    /** Primary navigation links */
    primaryNav: Link[];
    /** Secondary navigation links */
    secondaryNav: Link[];
  } & HTMLAttributes<HTMLDivElement>;

  const {
    title,
    copy,
    primaryCta,
    secondaryCta,
    primaryNav,
    secondaryNav,
    class: className = "",
    ...props
  }: FooterProps = $props();
</script>

<style>
  .footer {
    background: var(--color-accent-50);
    padding: var(--size-24) 0;
    color: var(--color-accent-800);
  }

  .inner {
    grid-auto-flow: dense;
    grid-row-gap: var(--size-8);
    @media (--lg) {
      grid-row-gap: var(--size-10);
    }
  }

  .content,
  .ctas {
    grid-column: 1 / -1;
    @media (--lg) {
      grid-column: 1 / span 6;
    }
    @media (--xl) {
      grid-column: 1 / span 5;
    }
  }

  .primary-nav,
  .secondary-nav {
    grid-column: 1 / -1;
    @media (--lg) {
      grid-column: 8 / -1;
    }
  }

  .ctas,
  .secondary-nav {
    @media (--lg) {
      grid-row: 2;
    }
  }

  .title {
    font: var(--display-md);
    font-weight: var(--weight-bold);
    @media (--md) {
      font: var(--display-lg);
      font-weight: var(--weight-bold);
    }
  }

  .copy {
    max-width: var(--prose-xs);
    margin: var(--size-5) 0 0;
    @media (--md) {
      font: var(--text-lg);
    }
  }

  .ctas {
    --button-color: var(--color-accent-800);
    display: flex;
    gap: var(--size-4);
  }

  .primary-nav {
    align-self: flex-end;
  }

  .logo {
    & :global(svg) {
      fill: currentColor;
      width: var(--size-16);
      height: var(--size-16);
    }
    @media not all and (--lg) {
      display: none;
    }
  }

  .nav {
    column-gap: var(--size-9);
    row-gap: var(--size-2);
    display: flex;
    flex-wrap: wrap;
    .primary-nav & {
      @media (--lg) {
        margin-top: var(--size-10);
      }
    }
  }

  .nav-item {
    display: block;
    white-space: nowrap;
    @media (--md) {
      font: var(--text-lg);
    }
    .primary-nav & {
      grid-column: span 1;
      font-weight: var(--weight-semibold);
    }
  }
</style>

<footer class="footer --grid-page {className}" {...props as any}>
  <div class="inner --grid-content">
    <div class="content">
      <h2 class="title">{title}</h2>
      <div class="copy --richtext"><Richtext value={copy} /></div>
    </div>
    <div class="ctas">
      <Button theme="secondary" href={secondaryCta.href}
        >{secondaryCta.label}</Button
      >
      <Button theme="primary" href={primaryCta.href}>{primaryCta.label}</Button>
    </div>
    <div class="primary-nav">
      <div class="logo">{@html logo}</div>
      <nav class="nav">
        {#each primaryNav as { label, href }}
          <a class="nav-item" {href}>{label}</a>
        {/each}
      </nav>
    </div>
    <div class="secondary-nav">
      <nav class="nav">
        {#each secondaryNav as { label, href }}
          <a class="nav-item" {href}>{label}</a>
        {/each}
      </nav>
    </div>
  </div>
</footer>
